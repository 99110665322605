module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","titleTemplate":"%s","title":"MAT","description":"The Official Website of MAT. All things MAT related.","canonical":"https://matdecoste.com","openGraph":{"type":"website","locale":"en_CA","site_name":"MAT","images":[{"url":"https://matdecoste.com/images/socials.png","width":1200,"height":1200,"alt":"Og Image"}]},"twitter":{"handle":"@handle","site":"@site","cardType":"summary_large_image"},"nav":{"instagram":"https://www.instagram.com/matdecoste","twitter":"https://twitter.com/matdecoste","soundcloud":"https://soundcloud.com/matdecoste","youtube":"https://youtube.com/channel/UCTWqJEenSO4TWX_S1AfHDFw","tikTok":"https://tiktok.com/@matdecoste"},"contact":{"text":"Contact","email":"info@matdecoste.com","header":{"subject":"Contact | MAT DECOSTE WEBSITE"}},"copyrights":"Mat Decoste © 2023 All rights reserved"},
    },{
      plugin: require('../../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","custom":[{"name":["Monument Grotesk"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
