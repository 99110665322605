import { useStaticQuery, graphql } from 'gatsby'

import { Flex } from 'theme-ui'
import { Text } from '@monobits/components'

import Cta from '../components/Cta'
import Nav from '../components/Nav'
import { Mark } from '../components/Header'

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          copyrights
          nav {
            instagram
            twitter
            soundcloud
            youtube
            tikTok
          }
          contact {
            email
            text
            header {
              subject
            }
          }
        }
      }
    }
  `)

  const { contact, copyrights, nav } = data.site.siteMetadata

  return (
    <Flex as="footer" variant="footer" {...props}>
      <Text as="h1">{copyrights}</Text>
      <Mark />
      <Nav data={nav} />
      <Cta data={contact} variant="inverted" className="button" />
    </Flex>
  )
}

export default Footer
