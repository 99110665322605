import { buildColors } from '@monobits/colors'

const modes = (_) => ({
  light: {
    background: _.pink,
    text: _.white,
    primary: _.white,
    highlight: _.transparent,
  },
  dark: {
    background: _.black,
    text: _.pink,
    primary: _.pink,
  },
})

export default (initial) =>
  buildColors(
    {
      modes,
      initialColorModeName: initial,
      colors: {
        pink: '#FFA9D2',
        white: '#FFFFFF',
        black: '#000000',
        transparent: 'rgba(0,0,0,0)',
      },
    },
    ['text', 'background', 'primary']
  )
