import { RecoilRoot } from 'recoil'
import { Sizes } from '@monobits/components'

import Layout from '../components/Layout'
import { ColorModeTransition } from '@monobits/color-transitions'

import rides from '../gsap/rides'

const Root = ({ children, location }) => {
  return (
    <RecoilRoot>
      <Sizes callbacks={location} />
      <ColorModeTransition
        extend={({ tl, target, options }) => {
          return tl.add(rides(target, options), 'start')
        }}
      />
      <Layout>{children}</Layout>
    </RecoilRoot>
  )
}

export default Root
