import { startCase } from 'lodash'

import { Underlined } from '@monobits/components'

const Nav = ({ data, ...props }) => {
  const entries = Object.entries(data)
  return (
    <nav sx={{ variant: 'nav' }} {...props}>
      {entries.map(([key, value], i) => (
        <Underlined as="a" key={'navitem' + i} className="item" href={value} target="_blank" rel="noreferrer noopener">
          {startCase(key)}
        </Underlined>
      ))}
    </nav>
  )
}

export default Nav
