import { buildFonts } from '@monobits/fonts'
import { makeFluids } from '@monobits/scales'

export const fluid = makeFluids(
  [
    ['--size-text', '10px', '18px'],
    ['--size-caption', '24px', '44px'],
    ['--size-title', '45px', '180px'],
  ],
  ['375px', '2500px']
)

export const scales = {
  fontSizes: fluid.scale,
  fontWeights: {
    body: 400,
    heading: 400,
  },
  lineHeights: {
    body: 1.35,
    heading: 1,
  },
}

export const rootFontStyles = {
  fontFamily: 'monument',
  ...fluid.properties,
}

const fonts = {
  monument: {
    family: 'Monument Grotesk',
    fallbacks: 'helvetica, arial, sans-serif',
    crop: [9.5, 8],
  },
}

export default buildFonts([fonts, scales], {
  variants: {
    key: 'text',
    heading: { variant: 'text.title' },
    __default: { variant: 'text.text' },
    __cropped: {
      title: {
        fontSize: 3,
        lineHeight: 'heading',
        crops: 'monument',
      },
      caption: {
        fontSize: 2,
        lineHeight: 'body',
        crops: 'monument',
      },
      text: {
        fontSize: 1,
        lineHeight: 'body',
        crops: 'monument',
        textTransform: 'uppercase',
      },
      small: {
        fontSize: '0.85em',
        lineHeight: 'body',
        crops: 'monument',
      },
    },
  },
})
