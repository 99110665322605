import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { omit } from 'lodash'

import scales, { rootScaleStyles } from './scales'
import fonts, { rootFontStyles } from './fonts'
import colors from './colors'
import overrides from './overrides'
import styles from './styles'

import * as variants from './variants'

export default merge(omit(preset, ['colors', '_modes', 'initialColorModeName']), {
  breakpoints: ['43em', '68em', '86em'],

  config: {
    initialColorModeName: 'light',
    useLocalStorage: false,
    useColorSchemeMediaQuery: false,
    colorTransitions: {
      duration: 0.75,
      ease: 'power1.inOut',
    },
    intro: {
      duration: 3.5,
      delay: 1,
    },
  },

  styles: {
    root: {
      '--theme-duration': '1s',
      '--theme-ease': 'ease-in-out',
      '--theme-speed': 'var(--theme-duration) var(--theme-ease)',

      '--custom-ease': 'cubic-bezier(0.16, 1, 0.3, 1)',
      '--hero-transtion': '2s var(--custom-ease)',
      '--footer-transtion': '1.5s var(--custom-ease)',
      '--footer-mark-delay': '0.2s',

      body: { bg: 'background' },

      userSelect: 'none',
      '&:not(.-ready)': { pointerEvents: 'none' },

      '&.-in-transition': {
        '*:not(.-with-transition), *:not(.-with-transition)::before, *:not(.-with-transition)::after': {
          transition: 'none!important',
        },
      },

      ...styles,
      ...rootScaleStyles,
      ...rootFontStyles,
    },
  },

  ...colors('light'),
  ...variants,
  ...overrides,
  ...scales,
  ...fonts,
})
