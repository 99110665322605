import { gsap } from 'gsap'
import { isMobile } from 'react-device-detect'

const device = isMobile ? 'touch' : 'mouse'

const REF = {
  light: '.light-ride',
  dark: '.dark-ride',
}
const FILTERS = {
  initial: 'brightness(1) contrast(1) saturate(1)',
  mouse: 'brightness(0.2) contrast(1.8) saturate(0.75)',
  touch: 'brightness(0.35) contrast(1.8) saturate(0.65)',
}

const rides = (mode, options = {}) => {
  const tl = gsap.timeline({ defaults: options })

  const { light, dark } = REF

  tl.addLabel('start') //
    .set([dark, light], { willChange: 'auto' }, 'start')

  if (mode === 'light') {
    tl.to(dark, { opacity: 0 }, 'start') //
      .to(light, { filter: FILTERS.initial }, 'start')
  } else {
    tl.to(dark, { opacity: 1 }, 'start') //
      .to(light, { filter: FILTERS[device] }, 'start')
  }

  return tl.set([dark, light], { clearProps: 'will-change' })
}

export default rides
