import { transitions } from 'polished'

const transition = (delay = '') =>
  transitions(['opacity', 'transform'], `var(--footer-transtion)${delay && ' ' + delay}`)

export default {
  flexDirection: ['column', null, 'row'],
  justifyContent: 'space-between',
  alignItems: 'center',

  pt: 1,
  borderTopWidth: 2,
  borderTopStyle: 'solid',
  borderTopColor: 'text',
  textTransform: 'uppercase',

  '& > * + *': {
    mt: [1, null, 0],
    ml: [0, null, 1],
  },

  '.button': {
    width: ['100%', null, 'unset'],
  },

  ...transition(),
  '.mark': transition('var(--footer-mark-delay)'),

  'html:not(.-footer-loaded) &': {
    opacity: 0,
    transform: 'translateY(8%)',

    '.mark': {
      opacity: 0,
      transform: 'translateY(25%)',
    },
  },
}
