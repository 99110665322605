import Obfuscate from 'react-obfuscate'

import { Button } from '@monobits/components'

const Cta = ({ data: { text, ...options }, ...props }) => (
  <Button as={Obfuscate} rel="noreferrer noopener" target="_blank" {...options} {...props}>
    <span>{text}</span>
  </Button>
)

export default Cta
