import { atom } from 'recoil'

export const targetMode = atom({
  key: 'targetMode',
  default: undefined,
})

export const imagesCount = atom({
  key: 'imagesCount',
  default: 0,
})
