import { gsap } from 'gsap'

const getInner = (ref, prop) => {
  const properties = {
    width: ['padding-left', 'padding-right'],
    height: ['padding-top', 'padding-bottom'],
  }
  const getter = gsap.getProperty(ref)
  const values = properties[prop].map((string) => getter(string))
  const sum = values.reduce((partial_sum, a) => partial_sum + a, 0)

  return getter(prop) - sum
}

export default getInner
