const mediaClasses = (display, { prepend = true } = {}) => {
  const pre = !prepend ? '' : '-' + display
  return {
    ['.-hide-mobile' + pre]: { display: ['none', display] },
    ['.-hide-tablet' + pre]: { display: [null, 'none', display] },
    ['.-hide-desk' + pre]: { display: [null, null, 'none'] },
    ['.-show-mobile' + pre]: { display: [null, 'none'] },
    ['.-show-tablet' + pre]: { display: ['none', display, 'none'] },
    ['.-show-desk' + pre]: { display: ['none', null, display] },
  }
}

export default {
  ...mediaClasses('grid'),
  ...mediaClasses('block'),
  ...mediaClasses('flex', { prepend: false }),
}
