import { gsap } from 'gsap'
import { setClass } from '../utils/utils'
import getInner from '../utils/getInner'

const intro = (refs, options = {}) => {
  const { container, logo } = refs

  const h = {
    container: getInner(container, 'height'),
    logo: getInner(logo, 'height'),
  }

  const { duration = 3.5, delay = 0 } = options

  const a = duration * 0.4
  const b = duration * 1 - a

  const state = {
    initial: {
      scaleY: 0,
      y: h.container - h.logo,
      transformOrigin: 'bottom',
      visibility: 'visible',
    },
    stretch: { scaleY: h.container / h.logo, delay },
    exits: { y: 0, scaleY: 1, clearProps: 'will-change' },
  }

  const tl = gsap.timeline()

  tl.set(logo, { ...state.initial })
    .addLabel('start', '+=0.1')
    .to(logo, { duration: a, ease: 'expo.in', ...state.stretch })
    .set(logo, { willChange: 'transform' }, `-=${a / 3}`)
    .to(logo, { duration: b, ease: 'expo.out', ...state.exits })
    .addLabel('end', `-=${a}`)
    .call(setClass, ['-hero-loaded'], 'end')
    .call(setClass, ['-footer-loaded'], `end+=0.5`)
    .call(setClass, ['-ready'], `end+=0.75`)

  return tl.pause('start')
}

export default intro
