import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  [
    ['--padding-x', '20px', '40px'],
    ['--padding-s', '30px', '50px'],
    ['--padding-m', '40px', '60px'],
    ['--padding-l', '50px', '70px'],
    ['--mark-size', '60px', '70px', false],
  ],
  ['375px', '2500px']
)

export const rootScaleStyles = {
  ...fluid.properties,
  '--stroke-x': '0.08em',
  '--stroke-2': '0.15rem',
}

export default {
  space: fluid.scale,
  borderWidths: [0, '0.08em', '0.15rem'],
}
