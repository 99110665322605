import { toCSSVar as _ } from '@monobits/theme-core'

export default {
  button: {
    styles: {
      '--speed': 'var(--theme-duration)',
      '--ease': 'var(--theme-ease)',
      transition: 'color var(--theme-speed)',
      '&::before': {
        borderRadius: '200px',
      },
    },
    variants: {
      inverted: {
        '--text': _`background`,
        '--text-hover': _`background`,
        '--bg': _`text`,
        '--bg-hover': _`text`,
      },
    },
    sizes: {
      md: { p: ['1.25em 3em', null, '0.75em 3em'] },
      __default: { variant: 'button.sizes.md' },
    },
  },
  link: {
    underlined: {
      fontSize: 1,
      whiteSpace: 'nowrap',

      '--stroke': 'var(--stroke-x, 0.07em)',
      '--gap': '-0.15em',
    },
  },
}
