import { forwardRef } from 'react'
import { Box } from 'theme-ui'
import { Icon } from '@monobits/components'

import LogoFull from '../../assets/logo-full.svg'
import LogoShort from '../../assets/logo-short.svg'
// import LogoMark from '../../assets/logo-mark.svg'
import LogoMark from '../../assets/mat-circle.svg'

const VARIANT = { HEADER: 'logos.header', HEAD: 'logos.logo', MARK: 'logos.mark' }

const Header = forwardRef(({ sx, ...props }, ref) => (
  <Box as="header" ref={ref} sx={{ variant: VARIANT.HEADER }}>
    <Icon icon={LogoFull} sx={{ variant: VARIANT.HEAD, ...sx }} className="-show-desk" {...props} />
    <Icon icon={LogoShort} sx={{ variant: VARIANT.HEAD, ...sx }} className="-hide-desk" {...props} />
  </Box>
))

export const Mark = ({ sx, ...props }) => (
  <Icon icon={LogoMark} sx={{ variant: VARIANT.MARK, ...sx }} className="mark" {...props} />
)

export default Header
