export default {
  header: {
    height: 'fit-content',
    'html:not(.-ready) &': { visibility: 'hidden' },
  },
  logo: {
    color: 'text',
    height: 'auto',
    gridRow: 'main',
    gridColumn: 'full',
  },
  mark: {
    maxWidth: 'var(--mark-size)',
  },
}
