export const setClass = (classname, active = true) => {
  const list = document.documentElement.classList
  if (active) {
    return list.add(classname)
  }
  return list.remove(classname)
}

export const toCSSVar = (name, raw = false) => {
  const property = `--theme-ui-colors-${name.toString().replace(/\./g, '-')}`
  return !raw ? `var(${property})` : property
}
