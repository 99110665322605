import { transitions } from 'polished'

export default {
  p: 1,
  variant: 'layout.section.vh',
  position: 'relative',
  overflow: 'hidden',

  gridRowGap: [3, 2, 1],
  gridTemplateColumns: '[full] 1fr',
  gridTemplateRows: '[main] 1fr [footer] auto',

  rides: {
    '--offby': ['40vw', '34vw', '11vw'],
    pt: 'var(--offby)',

    width: '100%',
    height: '100%',

    position: 'absolute',
    justifyContent: 'center',

    display: 'grid',
    gridRow: 'main',
    gridColumn: 'full',

    ...transitions(['opacity', 'transform'], 'var(--hero-transtion)'),

    'html:not(.-hero-loaded) &': {
      opacity: 0,
      transform: 'translateY(12%)',
    },
  },

  image: {
    pointerEvents: 'none',
    gridRow: 'main',
    gridColumn: 'full',

    height: '100%',
    width: '100%',

    'img, source': {
      objectFit: 'contain!important',
    },

    '&.dark-ride': { mixBlendMode: 'screen' },
    '&.light-ride': { filter: 'brightness(1) contrast(1) saturate(1)' },
  },

  'html[theme-mode=light] &': {
    '.dark-ride': { opacity: 0 },
  },
}
