import { useRecoilValue } from 'recoil'
import { useRef, useState, useEffect } from 'react'
import { useMedia } from 'react-use'

import { useModes } from '@monobits/color-transitions'
import { Grid, useThemeUI } from 'theme-ui'

import { imagesCount } from '../atoms'

import Header from './Header'
import Footer from './Footer'
import intro from '../gsap/intro'

const Layout = ({ children, ...props }) => {
  const context = useThemeUI(null)
  const options = context.theme?.config?.intro

  const isMobile = useMedia('(hover: none)', false)
  const { setTarget, initial, next } = useModes({ context })

  const [played, setPlayed] = useState(false)
  const imagesLoaded = useRecoilValue(imagesCount) === 2

  let refs = { container: useRef(null), logo: useRef(null) }

  useEffect(() => {
    if (!played && imagesLoaded) {
      intro(refs, options).play()
      setPlayed(true)
    }
  }, [played, imagesLoaded]) // eslint-disable-line

  return (
    <Grid as="main" ref={(e) => (refs.container = e)} variant="hero" {...props}>
      <Header ref={(e) => (refs.logo = e)} />
      {children}
      <Footer
        className="-with-transition"
        onPointerEnter={() => !isMobile && setTarget(next)}
        onPointerLeave={() => !isMobile && setTarget(initial)}
      />
    </Grid>
  )
}

export default Layout
